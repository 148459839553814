.devtools-bubble {
    position: fixed;
    bottom: 50px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-size: 24px;
    z-index: 1000;
  }
  
  /* Khung nội dung DevTools */
  .devtools-panel {
    position: fixed;
    bottom: -100%;
    right: 0;
    width: 100%;
    max-height: 50%;
    background: #808080e0;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    transition: bottom 0.3s ease-in-out;
    z-index: 999;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    border-radius: 0;
    max-width: 500px;
    color: white;
  }
  
  /* Khi mở khung thì dịch chuyển lên */
  .devtools-panel.open {
    bottom: 0;    overflow: scroll;
  }
  
  /* Header DevTools */
  .devtools-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #007bff;
    color: white;
    position: fixed;
    width: 100%;

  }
  
  /* Nội dung DevTools */
  .devtools-content {
    padding: 20px;
    /* overflow-y: scroll; */
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .chat-message {
    margin-bottom: 0.3rem;
  }
  .devtools-panel.open .chat-input {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 500px;
    float: right;
  }
  .jackpot-bubble {
    position: fixed;
    bottom: 40px;
    right: 85px;
    background-image: url('/public/imgs/Jackpot_Box.svg');
    background-size: cover;
    background-position: center;
    color: white;
    width: 200px;
    line-height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    z-index: 1000;
  }