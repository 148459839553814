body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Variables */
:root {
  --icon-fontSize-sm: 1rem;
  --icon-fontSize-md: 1.25rem;
  --icon-fontSize-lg: 1.5rem;
}

*:focus-visible {
  outline: 2px solid var(--mui-palette-primary-main);
}

html {
  height: 100%;
}

body {
  height: 100%;
}
.wrapper {
  padding-left: var(--cui-sidebar-occupy-start, 0);
}

.header-toggler-icon {
  width: 25px !important;
}