.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-nav .nav-link {
  color: #fff;
  font-weight: bold;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.body {
  background: url('/public/imgs/background-body.png');
  background-color: #084092;
}
.header {
  background: url('/public/imgs/background.png');
  background-size: 30px;
}
.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
  vertical-align: text-top;
  
}

@keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}


.content {
  position: relative;
  width: 880px;
  margin: 0 auto;
  max-width: 100%;
  /* padding: 20px; */
}
.content video {
  width: 100%;
  display: block;
}
.content .overlay {
  content: '';
  position: absolute;
  /* background: url('/public/new_theme/khungtren.png'); */
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;    z-index: 999;
  text-align: center;
  background-repeat: no-repeat;
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  height: 20%;
  line-height: 20px;
}
.content .resultOverlay {
  content: '';
  position: absolute;
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;    z-index: 999;
  text-align: center;
  background-repeat: no-repeat;
  font-size: 65px;
  font-style: italic;
  font-weight: bold;
  color: #fff;
  height: 100%;
  line-height: 55px;
  display: none;
  flex-direction: column;
    justify-content: center; /* Căn giữa theo chiều dọc */
    align-items: center;
    background: radial-gradient(circle, black 0%, rgb(0 0 0 / 92%) 70%);
}
.jackpotTitleOverlay {
  bottom: 80px;
  position:absolute;
  color: #FFD700; 
  font-size: 24px; 
  font-style:normal
}
.jackpotValueOverlay {
  bottom: 50px;
  position:absolute;
  color: #FFD700; 
  font-size: 24px; 
  font-style:normal
}
.content .resultOverlay.active {
  display: flex; /* Hiển thị khi có class active */
}
.resultOverlay.active {
  display: flex; /* Hiển thị khi có class active */
}
.resultOverlay.active.wala{
  color: #184e82;
}
.resultOverlay.active.meron{
  color: #e60409;
}
.resultOverlay.active.draw{
  color: #127a47;
}
.resultOverlayText {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideInFromLeft 1s forwards;
  animation-delay: 0.2s; /* Thời gian trễ trước khi bắt đầu */
}

.resultOverlaySubText {
  opacity: 0;
  transform: translateX(100%);
  animation: slideInFromRight 1s forwards;
  animation-delay: 0.4s; /* Thời gian trễ trước khi bắt đầu */
}
@keyframes slideInFromLeft {
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  to {
      opacity: 1;
      transform: translateX(0);
  }
}
.content .fightNumOverlay {
  content: '';
  /* width: 100px; */
  padding: 10px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  background: #ff000036;
  background-size: cover;
  border-radius: 5px;
  top: 8px;
  left: 14px;    
  z-index: 999;
  text-align: center;
  background-repeat: no-repeat;
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}
.content .fightNumOverlay.betting {
  background-color: #084092;
}
.content .fightNumOverlay.end {
  background-color: green;
}
.historyCircle {
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
@media (max-width: 600px) {
  .content .fightNumOverlay {
    font-size: calc(1vw + 4px); /* Smaller font-size for smaller screens */
  }
}

@media (min-width: 1200px) {
  .content .fightNumOverlay {
    font-size: calc(1vw + 6px); /* Larger font-size for larger screens */
  }
}

.content .fightStatusOverlay {
  content: '';
  width: 100px;
  height: 60px;
  position: absolute;
  /* background: url('/public/new_theme/live.png'); */
  border-radius: 5px;
  top: 5px;
  right: 0;
  bottom: 0;
  z-index: 999;
  text-align: center;
  background-repeat: no-repeat;
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  line-height: 53px;
}

@media (max-width: 668px) {
 .overlay {
  background-size: contain !important;
  font-size: 1em !important;
 }
 .fightNumOverlay {
  top: 0px !important;
  width: 50px !important;
  background-size: contain !important;
 }
 .fightStatusOverlay {
  width: 80px !important;
  background-size: contain !important;
  right: 20px !important;
  line-height: 23px !important;
  font-size: 14px !important;
 }
 .historyCircle {
    padding: 0px !important;
    text-align: center;
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
  .content .resultOverlay {
    font-size: 30px !important;
    line-height: 20px !important;
  }
  .jackpotTitleOverlay {
    bottom: 30px;
    position:absolute;
    color: #FFD700; 
    font-size: 14px; 
    font-style:normal
  }
  .jackpotValueOverlay {
    bottom: 10px;
    font-size: 24px; 
  }
  .tab-bets {
    height: 220px;
    overflow-y: scroll;
  }
}
