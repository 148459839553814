.dot {
  height: 15px;
  width: 15px;
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 10px;
}
@media (max-width: 668px) {
  .dot {
    height: 15px;
    width: 15px;
    font-size: 10px;
  }
}
.meron-sum {
  border: 3px solid #ff0000;
}
.wala-sum {
  border: 3px solid #0a7ec5;
}
.draw-sum {
  border: 3px solid #2bae70;
}
.cancel-sum {
  border: 3px solid #656566;
}
.meron,
.m {
  background-color: #ff0000;
}
.wala,
.w {
  background-color: #0a7ec5;
}
.draw,
.d {
  background-color: #2bae70;
}
.cancel,
.c {
  background-color: #656566;
}
