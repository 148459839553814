.hallContainer {
    background-color: #052e66;
}
.betArea {
    background-color: #0a4080;
}
.betHeader {
    padding: 14px;
    background-color: #0a4080;
    color: #fff;
    font-weight: bold;
    border-bottom: 8px solid #052e66;
}
.partyPicker {
    width: 100%;
    background-color: #184e82;
    color: #fff;
    text-align: center;
    border: 1px solid;
    font-weight: bold;
}
.pickermeron{
    background: #e6050a;
}
.pickerdraw{
    background: #137a48;
}
.pickerwala{
    background: #1d2a98;
}
.betButton {
    background-Color:#184e82;
    border: none;
    padding: 20px
}
.userReportPicker {
    background: transparent !important;
    color: #fff !important;
    font-weight: bold;
    border: none !important;
}
.userReportPickerActive {
    border-bottom: 2px solid #fff !important;
}
.partyBar {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    margin-top: -6px;
}
.partyMeron {
    color: #fff;
    font-style: italic;
    font-weight: bold;
    font-size: 26px;
    background: rgb(255,0,0);
    background: radial-gradient(circle, rgba(255,0,0,0.7637648809523809) 0%, rgba(255,0,0,1) 100%);
}
.partyWala {
    color: #fff;
    font-style: italic;
    font-weight: bold;
    font-size: 26px;
    background: rgb(39,82,158);
    background: radial-gradient(circle, rgba(39,82,158,0.8225884103641457) 0%, rgba(24,59,130,1) 100%);
}
.betValue {
    border-radius: 50%;
    max-width: 100%;
}
.betValueActive{
    box-shadow: 0 0 0.35em 0.35em rgb(255 255 255);
}
.chipSettings {
    background: #184e82;
    border-radius: 14px;
}
.hallPicker {
    border-radius: 0 !important;
    background-color: #184e82;
    padding: 5px;
    font-size: 16px;
}
.hallViewing {
    box-shadow:inset 0 -3px 0 #ffffffb3;
    background-color: #0a4080;
    font-weight: bold;
}